import { Select } from 'component-library'
import './HighPerformanceMobile.scss'
import {
  ITabCombination,
  ITabs,
} from '../HighPerformanceSection/HighPerformanceSection'
import { Dispatch, SetStateAction } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { detectDisabledTabs } from '../../../utils/tab_helpers'
import CodeBox from '../../TabbedCode/CodeBox/CodeBox'

interface IHighPerformanceMobileProps {
  tabs: ITabs
  selectedTabCombination: ITabCombination
  setSelectedTabCombination: Dispatch<SetStateAction<ITabCombination>>
}

const HighPerformanceMobile = (props: IHighPerformanceMobileProps) => {
  const { tabs, selectedTabCombination, setSelectedTabCombination } = props

  const getDropdownOptions = (labels: string[]) => {
    return labels.map((label) => {
      return { label: label, value: label }
    })
  }

  const updateTabCombination = (
    newValue:
      | MultiValue<{ value: string; label: string }>
      | SingleValue<{ value: string; label: string }>,
    index: number
  ) => {
    const newValueLocal = newValue as { label: string; value: string }
    const selectedCombinationArray = [...selectedTabCombination.combination]
    selectedCombinationArray[index] = newValueLocal.label
    const newTabCombination = tabs.combinations.find(
      (combo) => combo.combination.join() === selectedCombinationArray.join()
    )
    if (newTabCombination) setSelectedTabCombination({ ...newTabCombination })
  }

  return (
    <div
      className="HighPerformanceMobile"
      data-testid="high-performance-mobile"
    >
      {tabs.sections.map((tabSection, tabSectionIndex) => {
        if (tabSection.sectionName) {
          return (
            <div
              className="HighPerformanceMobile__select-wrapper"
              key={tabSection.sectionName}
            >
              <Select
                formName="high-performance-tabs"
                fieldName={tabSection.sectionName}
                fieldLabel={tabSection.sectionName}
                options={getDropdownOptions(tabSection.labels)}
                onChange={(value) =>
                  updateTabCombination(value, tabSectionIndex)
                }
                value={{
                  value: selectedTabCombination.combination[tabSectionIndex],
                  label: selectedTabCombination.combination[tabSectionIndex],
                }}
                isOptionDisabled={(option) =>
                  detectDisabledTabs(
                    tabs.combinations,
                    selectedTabCombination.combination
                  ).includes(option.label)
                }
              />
            </div>
          )
        }
      })}
      <div>
        <div className="mb-2">
          <span className="text-sm">Code</span>
        </div>
        <CodeBox selectedTabCombination={selectedTabCombination} />
      </div>
    </div>
  )
}

export default HighPerformanceMobile
