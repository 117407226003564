import SubSectionLayout from '../../SubSectionLayout/SubSectionLayout'
import TabbedCode from '../../TabbedCode/TabbedCode'
import './HighPerformanceSection.scss'
import HighPerformanceMobile from '../HighPerformanceMobile/HighPerformanceMobile'
import { useState } from 'react'

export interface ITabCombination {
  combination: string[]
  code_comment: string
  code: string
  note?: string
}

export interface ITabs {
  sections: { labels: string[]; sectionName?: string }[]
  combinations: ITabCombination[]
}

interface IHighPerformanceSectionProps {
  cta_hero: {
    title: string
    image: string
    description: string
    cta_links: { link_text: string; link: string }[]
    image_right_aligned: boolean
  }
  tabs: ITabs
  bottom_link?: {
    text: string
    url: string
  }
}

const HighPerformanceSection = (props: IHighPerformanceSectionProps) => {
  const { cta_hero, tabs, bottom_link } = props
  const { title, image, description, cta_links, image_right_aligned } = cta_hero

  const [selectedTabCombination, setSelectedTabCombination] =
    useState<ITabCombination>(tabs.combinations[0])

  return (
    <div data-testid="high-performance-section">
      <SubSectionLayout
        title={title}
        image={image}
        description={description}
        links={cta_links}
        image_right_aligned={image_right_aligned}
      />
      <div className="HighPerformanceSection__tabs">
        <TabbedCode
          tabs={tabs}
          bottom_link={bottom_link}
          selectedTabCombination={selectedTabCombination}
          setSelectedTabCombination={setSelectedTabCombination}
        />
      </div>
      <HighPerformanceMobile
        tabs={tabs}
        selectedTabCombination={selectedTabCombination}
        setSelectedTabCombination={setSelectedTabCombination}
      />
    </div>
  )
}

export default HighPerformanceSection
