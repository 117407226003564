import { useMemo, useState } from 'react'
import copy from 'copy-to-clipboard'
import { ITabCombination } from '../../InstallPage/HighPerformanceSection/HighPerformanceSection'
import './CodeBox.scss'

interface ITabbedCodeBoxProps {
  selectedTabCombination: ITabCombination
}

const CodeBox = (props: ITabbedCodeBoxProps) => {
  const { selectedTabCombination } = props

  const [copyButtonText, setCopyButtonText] = useState<string>('Copy')

  const currentTabCode = useMemo(() => {
    return String(selectedTabCombination.code)
      .replaceAll(/^\s*$(?:\r\n?|\n)/gm, '') // Remove blank lines
      .replace(/^ +/gm, '') // Remove space from the start of each line
  }, [selectedTabCombination])

  const getCodeLines = (codeLines: string) => {
    return codeLines.split('\n').filter((line) => line.length > 0)
  }

  return (
    <div className="CodeBox" data-testid="code-box">
      <div className="CodeBox__scroll-container">
        {selectedTabCombination.code_comment.length > 0 && (
          <div className="text-grey-5">
            <div className="whitespace-nowrap">
              {getCodeLines(selectedTabCombination.code_comment).map(
                (comment, commentIndex) => {
                  return (
                    <div key={`comment-${commentIndex}`}>
                      <span className="pr-10">{comment}</span>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}
        {selectedTabCombination.code.length > 0 && (
          <div className="CodeBox__code">
            {getCodeLines(selectedTabCombination.code).map(
              (codeLine, codeLineIndex) => {
                return (
                  <div key={`code-line-${codeLineIndex}`}>
                    <span>{codeLine}</span>
                  </div>
                )
              }
            )}
          </div>
        )}
        {selectedTabCombination.note && (
          <div className="text-grey-5">
            <p>{selectedTabCombination.note}</p>
          </div>
        )}
      </div>

      {selectedTabCombination.code.length > 0 && (
        <button
          onClick={() => {
            copy(currentTabCode)
            setCopyButtonText('Copied!')
            setTimeout(() => setCopyButtonText('Copy'), 2000)
          }}
          className="CopyButton"
        >
          {copyButtonText === 'Copy' ? (
            <>
              <i className="bx bx-copy"></i> {copyButtonText}
            </>
          ) : (
            copyButtonText
          )}
        </button>
      )}
    </div>
  )
}

export default CodeBox
