import LinkComponent from '../../LinkComponent/LinkComponent'
import HardwarePartnerCard from '../HardwarePartnerCard/HardwarePartnerCard'
import './InstallHardware.scss'

interface IHardwarePartnerCardProps {
  title: string
  description: string
  partners: { link: string; image: string; aria_label: string }[]
  all_plugins_link: { text: string; url: string }
}

const InstallHardware = (props: IHardwarePartnerCardProps) => {
  const { title, description, partners, all_plugins_link } = props
  return (
    <div data-testid="install-hardware">
      <div className="w-1/2 h-[1px] bg-grey-6 mx-auto mt-10 mb-12"></div>
      <div className="InstallHardware__inner-container">
        <div className="max-w-[451px]">
          <h2 className="mb-6 leading-[36px] text-[#232323]">{title}</h2>
          <p className="text-[16px] leading-[25.6px] text-grey-2">
            {description}
          </p>
        </div>
        <ul className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
          {partners.map((partner) => {
            return (
              <li key={partner.link}>
                <HardwarePartnerCard partner={partner} />
              </li>
            )
          })}
          <li>
            <LinkComponent
              href={all_plugins_link.url}
              className="AllPluginsCard"
              testId="all-plugins-card"
            >
              <div>
                <span>{all_plugins_link.text}</span>
              </div>
              <div className="AllPluginsCard__icon">
                <i className="bx bx-chevron-right"></i>
              </div>
            </LinkComponent>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InstallHardware
