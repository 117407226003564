import { ITabCombination } from '../components/InstallPage/HighPerformanceSection/HighPerformanceSection'

const getValidCombinations = (allCombinations: ITabCombination[]) => {
  const validCombinations: Set<string> = new Set()
  // Find all valid combinations
  allCombinations.forEach((combination) => {
    validCombinations.add(combination.combination.join(','))
  })
  return validCombinations
}

// Disable tabs that would result in an invalid combination.
export const detectDisabledTabs = (
  allCombinations: ITabCombination[],
  selectedCombination: string[]
): string[] => {
  const disabledTabs: string[] = []
  // Check each option in the selected combination
  selectedCombination.forEach((_, index) => {
    // Clone the selected combination to avoid mutation
    const cloneCombination = [...selectedCombination]
    // Try replacing the current option with each other available option
    allCombinations.forEach((combination) => {
      const tempClone = cloneCombination.slice()
      tempClone[index] = combination.combination[index]
      if (!getValidCombinations(allCombinations).has(tempClone.join(','))) {
        disabledTabs.push(combination.combination[index])
      }
    })
  })
  // Remove duplicate disabled tabs
  return Array.from(new Set(disabledTabs))
}
